<template>
  <div class="app-container">
    <div class="filter-container" style="margin-bottom: 15px">
      <el-form :inline="true">
        <el-form-item label="登记主体名称:">
          <el-input
            v-model="listQuery.name_search"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="供销单位名称:">
          <el-input
            v-model="listQuery.cor_name_search"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-divider direction="vertical"></el-divider>
        <!--        <el-form-item label="经营商品类型:">-->
        <!--          <el-checkbox-group v-model="listQuery.operator_types">-->
        <!--            <el-checkbox label="1">进口冷冻食品</el-checkbox>-->
        <!--            <el-checkbox label="2">预包装食品</el-checkbox>-->
        <!--            <el-checkbox label="3">食用农产品</el-checkbox>-->
        <!--          </el-checkbox-group>-->
        <!--        </el-form-item>-->
        <el-form-item label="被添加为供销单位登记状态:">
          <el-radio-group v-model="listQuery.status_search">
            <el-radio label="1">已登记</el-radio>
            <el-radio label="2">未登记</el-radio>
            <el-radio :label="''">全部</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-divider direction="vertical"></el-divider>
        <el-form-item label="统计类型:">
          <el-radio-group v-model="listQuery.type_search">
            <el-radio label="1">以域内主体查</el-radio>
            <el-radio label="2">以他方主体查</el-radio>
            <el-radio label="3">全部</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-divider direction="vertical"></el-divider>
        <el-form-item label="是否首站:">
          <el-radio-group v-model="listQuery.sz_search">
            <el-radio label="1">是</el-radio>
            <el-radio label="3">全部</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-divider direction="vertical"></el-divider>
        <el-form-item label="收发货状态:">
          <el-radio-group v-model="listQuery.sr_status">
            <el-radio label="1">已进行收发货</el-radio>
            <el-radio label="2">未进行收发货</el-radio>
            <el-radio label="3">全部</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">筛选</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="handleExport()">导出</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="加载中..."
      border
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="登记主体名称">
        <template slot-scope="scope">
          <span>{{
              scope.row.name +
              "(" +
              "联系人:" +
            (scope.row.user_name?scope.row.user_name:'无') +
              "|联系电话:" +
            (scope.row.user_phone?scope.row.user_phone:'无') +
              ")"
            }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="登记主体统一社会信用代码">
        <template slot-scope="scope">
          <span>{{ scope.row.social_code }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="登记主体所选区域">
        <template slot-scope="scope">
          <span>{{
              (scope.row.province ? scope.row.province : "") +
              (scope.row.city ? scope.row.city : "") +
              (scope.row.area ? scope.row.area : "") +
              (scope.row.street ? scope.row.street : "") +
              (scope.row.village ? scope.row.village : "")
            }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="登记主体经营地址">
        <template slot-scope="scope">
          <span>{{ scope.row.full_address }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="供销单位名称">
        <template slot-scope="scope">
          <span>{{
              (scope.row.unit_company_id!='null'
                ? scope.row.cor_company_name
                : scope.row.cor_name) +
              (scope.row.unit_company_id!='null'
                ? "(" +
                "联系人:" +
                scope.row.cor_company_user_name +
                "|联系电话:" +
                scope.row.cor_company_user_phone +
                ")"
                : "(" +
                "联系人:" +
                scope.row.cor_user_name +
                "|联系电话:" +
                scope.row.cor_user_phone +
                ")")
            }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="供销单位统一社会信用代码">
        <template slot-scope="scope">
          <span>{{
              scope.row.unit_company_id!='null'
                ? scope.row.cor_company_social_code
                : scope.row.cor_social_code
            }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="供销单位状态">
        <template slot-scope="scope">
          <span>{{ scope.row.unit_company_id!='null' ? "已登记" : "未登记" }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="收发货状态">
          <span>{{ sr_status_txt }}</span>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        background
        hide-on-single-page
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="Number(listQuery.count)"
        layout="total, sizes, prev, pager, next, jumper"
        :total="listTotal"

      ></el-pagination>
    </div>

    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="[url]"
    />
  </div>
</template>

<script>

import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import axios from "../../../util/api";
export default {
  components: {
    ElImageViewer,
  },
  data() {
    return {
      list: null,
      listQuery: {
        pager_offset: "0",
        count:"10",
        status_search:"",
        name_search:"",
        cor_name_search:"",
        type_search: "1",
        sz_search: "3",
        sr_status: "3",
      },
      sr_status_txt: "",
      listTotal: null,
      listLoading: false,
      url: "",
      showViewer: false,
      currentPage:1,
      baseUrl: process.env.VUE_APP_BASE_API,
    };
  },
  filters: {},
  computed: {},
  created() {
  },
  mounted() {
    this.fetchData(this.listQuery);
  },
  methods: {
    get_sr_status() {
      this.sr_status_txt = this.listQuery.sr_status === "3" ? "全部" : this.listQuery.sr_status === "2" ? "未进行收发货" : "已进行收发货";
    },
    handleExport() {
      this.loading = true;
      const secounds = 5
      let num = 0
      this.timer = setInterval(() => {
        if (num < secounds) {
          num++
          this.check("export-cor_unit-list");
        } else {
          clearInterval(this.timer)
          this.loading = false
        }
      }, 2000)
      // this.timer = setInterval(() => { this.check("export-record-list"); }, 2000);
      let statename
      statename = '上下游主体登记统计表';

      axios.fileget('/pc/export-cor_unit-list', {downName:statename}).then((v) => { })

    },
    check (d_name) {
      axios.get('/pc/check', { down_name: d_name }).then((v) => {})

    },
    download(res) {
      if (!res) {
        return;
      }
      const url = window.URL.createObjectURL(
        new Blob([res], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        })
      );
      let fname = "供销单位统计表.xlsx";
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", fname);
      document.body.appendChild(link);
      link.click();
    },
    fetchData() {
      this.listLoading = true;
      axios.get("pc/v1/cor_unit",this.listQuery).then((response) => {
        this.get_sr_status();
        let res = response.data;
        if (res){
          this.list = res.result;
          this.listTotal = res.total;
        }
        this.listLoading = false;
      });
    },
    handleSearch() {
      this.listQuery.pager_offset = "0";
      this.fetchData();
    },
    handleSizeChange(val) {
      this.listQuery.count = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.listQuery.pager_offset = String((val - 1) * 10);
      this.fetchData();
    },
    handleViewPic(path) {
      this.url = this.baseUrl + path;
      this.showViewer = true;
    },
    closeViewer() {
      this.showViewer = false;
    },
  },
};
</script>
<style scoped>
.container {
  width: 95%;
  margin: 15px auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
